import React from 'react'

const Footer = () => {
    return (
        <footer className="footer">
        <div className="container text-center">
          <span className="alo">Athena Tech Services</span>
          <ul className="social list-inline">
            <li><a href="https://twitter.com/AthenaTech_Serv"><i className="icon icon-social-twitter" /></a></li>
            <li><a href="https://www.linkedin.com/company/athena-tech-service"><i className="icon icon-social-linkedin" /></a></li>
          </ul>
        </div>
      </footer>
    )
}

export default Footer
