import About from "./components/About";
import Contact from "./components/Contact";
import Services from "./components/Services";
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import Hero from "./components/Hero";

function App() {
  return (
    <div >
      {/* navbar section */}
        <Nav/>
      {/* hero section */}
        <Hero/>
       {/* about Section */}
       <About/>
      {/* our services */}
       {/* contact us */}
       <Contact/>
       {/* footer */}
       <Footer/>
      </div>

  );
}

export default App;
