import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Hero = () => {
	return (
		<div id="home">
			<Carousel autoPlay infiniteLoop interval="6000" showStatus={false} showThumbs={false}>
				<div
					style={{
						backgroundImage: "url('images/bg-1.jpg')",
						backgroundRepeat: 'no-repeat',
						height: '100vh',
						backgroundSize: 'cover'
					}}
				>
					{/* <img src="images/bg-1.jpg" alt="desk" data-bgfit="cover" data-bgposition="left top" data-bgrepeat="no-repeat"/> */}
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							height: '100%',
							textAlign: 'center',
							justifyContent: 'center'
						}}
					>
						<div
							className="slider-title"
							data-x="center"
							data-y="center"
							data-voffset="-30"
							data-speed="500"
							data-start="1200"
							data-easing="Power4.easeInOut"
						>
							welcome to <span>Athena Tech Services</span>
						</div>

						<div
							className="slider-caption"
							data-x="center"
							data-y="center"
							data-voffset="40"
							data-speed="500"
							data-start="1800"
							data-easing="Power4.easeInOut"
							data-captionhidden="on"
						>
							Fighting poverty through digital platforms!
						</div>

						<div
							className="slider-button scroll-to"
							data-x="center"
							data-y="center"
							data-voffset="120"
							data-speed="500"
							data-start="2400"
							data-easing="Power4.easeInOut"
							data-captionhidden="on"
						>
							<a className="btn btn-white" href="#about">
								See more
							</a>
						</div>
					</div>
				</div>
				<div
					style={{
						backgroundImage: "url('images/bg-3.jpg')",
						backgroundRepeat: 'no-repeat',
						height: '100vh',
						maxWidth: '100%',
						backgroundSize: 'cover'
					}}
				>
					{/* <img src="images/bg-1.jpg" alt="desk" data-bgfit="cover" data-bgposition="left top" data-bgrepeat="no-repeat"/> */}
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							height: '100%',
							textAlign: 'center',
							justifyContent: 'center'
						}}
					>
						<div
							className=" slider-title"
							data-x="center"
							data-y="center"
							data-voffset="-30"
							data-speed="500"
							data-start="1200"
							data-easing="Power4.easeInOut"
						>
							MODERN <span>DIGITAL PLATFORM BUILDER</span>
						</div>

						<div
							className="slider-caption"
							data-x="center"
							data-y="center"
							data-voffset="40"
							data-speed="500"
							data-start="1800"
							data-easing="Power4.easeInOut"
							data-captionhidden="on"
						>
							We are building the future through digital platforms
						</div>

						<div
							className="slider-button scroll-to"
							data-x="center"
							data-y="center"
							data-voffset="120"
							data-speed="500"
							data-start="2400"
							data-easing="Power4.easeInOut"
							data-captionhidden="on"
						>
							<a className="btn btn-white" href="#about">
								See more
							</a>
						</div>
					</div>
				</div>
			</Carousel>
		</div>
	);
};

export default Hero;
